<template>
  <div>
    <h4 class="pl-2 font-weight-bold">ゲーム管理</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  locale="ja"
                  @input="fromDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  locale="ja"
                  v-model="toDate"
                  @input="endDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
              <b-button
                class="primary mb-4 mb-md-3 ml-2"
                block
                @click="infoModal()"
                >新規作成 <i class="fa fa-plus" aria-hidden="true"></i
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="['title', 'description', 'createdDate']"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>
      <template #cell(title)="row">
        {{ row.item.title }}
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="infoModal(row.item)"
          ><b-badge variant="primary" class="py-2">更新</b-badge
          ><esports-loading-button
            v-if="row.item.gameId === gameInfo.gameId"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block"
          @click="deleteModal(row.item)"
          ><b-badge variant="danger" class="py-2">削除</b-badge></span
        >
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row)"
          ><b-badge variant="info" class="text-white py-2"
            >{{ row.detailsShowing ? "詳細非表示" : "詳細表示" }}</b-badge
          >
          <esports-loading-button
            v-if="row.item.isLoading"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
      </template>
      
      <template #row-details="row">
        <b-card no-body class="overflow-hidden animate__animated" :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']">
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <b-card-img-lazy
                :src="row.item.picture"
                alt="Image"
                class="rounded-0"
                v-bind="mainProps('#fff')"
              ></b-card-img-lazy>
              <label class="mt-1 ml-1" v-if="row.item.content">{{ row.item.content.labelFileName }}</label>
            </b-col>
            <b-col md="7" lg="7">
              <b-card-body>
                <h5>{{ row.item.title }}</h5>
                <b-card-text>
                  {{ row.item.description }}
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Game ID</b>: {{ row.item.gameId }}</li> -->
                <li><b>作成日</b>: {{ row.item.createdDate }}</li>
                <li v-if="row.item.content && row.item.content.extensionInfos">
                  <b>追加項目</b>:
                  <template>
                    <p
                      class="mb-0"
                      v-for="(info, index) in Object.entries(
                        row.item.content.extensionInfos
                      )"
                      :key="index"
                    >
                      <b>{{ info[0] }}</b
                      >: {{ info[1] }}
                    </p>
                  </template>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging 
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult">
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 ml-3"
        ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="gameInfo.id"
      :title="gameInfo.title_modal"
      ref="game-info-modal"
      @hide="resetgameInfo"
      @shown="handleUpdateGame"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <b-form-input
            v-model="gameInfo.title"
            type="text"
            placeholder="ゲーム"
            :state="ckStateGame('title')"
          ></b-form-input>
          <b-form-invalid-feedback>ゲームは必須です。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-form-textarea
            placeholder="概要"
            v-model="gameInfo.description"
            rows="3"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback
            >概要は必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >追加項目
            <span
              role="button"
              @click="addAttribute"
              v-if="gameInfo.extensionInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group 
            enter-active-class="animate__animated animate__slideInUp" 
            leave-active-class="animate__animated animate__fadeOutDown"
          >
            <div
              class="d-flex align-items-center mb-2 position-relative"
              v-for="(item, index) in gameInfo.extensionInfos"
              :key="`key-${index}`"
            >
              <b-row>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="項目名"
                      v-model="item.key"
                      :state="ckStateExtentInfo(index, 'key')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >項目名は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="値"
                      v-model="item.value"
                      :state="ckStateExtentInfo(index, 'value')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >値は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                class="pl-2 btn-remove-attribute"
                @click="removeAttribute(index)"
              >
                <i
                  class="fa fa-times text-danger"
                  aria-hidden="true"
                  role="button"
                ></i>
              </div>
            </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="position-relative">
          <b-form-file
            v-model="gameInfo.file"
            placeholder=""
            :state="gameId ? '' : ckStateGame('file')"
            drop-placeholder=""
            class="hide-banner"
            accept="image/*"
          ></b-form-file>
          <label class="custom-file-label filename">{{ filename }}</label>
          <b-form-invalid-feedback>画像アップロードは必須です。</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="onSubmitGame(close)"
          >{{ gameId ? '更新' : '登録' }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>

    <!-- delete Game Modal -->
    <b-modal
      id="deleteGame"
      hide-header
      header-class="text-danger"
      centered
    >
      <div class="form-data">
        <h5 class="text-center py-3">このゲームを完全に削除してもよろしいでしょうか?</h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleDeleteGame(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export default {
  name: "GameList",
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      gameId: "",
      items: [],
      fields: [
        {
          key: "title",
          label: "ゲーム",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "description",
          label: "概要",
          sortable: true,
          class: 'w-50'
        },
        {
          key: "createdDate",
          label: "作成日",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        title: "",
        description: "",
        createdDate: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      gameInfo: {
        id: "info-modal",
        file: null,
        title_modal: "",
        title: "",
        description: "",
        extensionInfos: [],
      },
      gameTemp: null,
      MAX_ATTRIBUTES: 5,
      msgErrors: [],
      msgSuccess: null,
      isEnableCloseModal: false,
      filename: '',
      limit: 0,
      isDetailRow: false
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.gameId) {
      return {
        gameInfo: {
          title: { required },
          extensionInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
        },
      };
    }
    return {
      gameInfo: {
        title: { required },
        file: { required },
        extensionInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
    'gameInfo.file': {
      deep: true,
      immediate: true,
      handler(nVal) {
        if(nVal) {
          this.filename = nVal.name;
        } else {
          this.filename = "画像をアップロード";
        }
      }
    }
  },
  computed: {
    isValidGame() {
      return !this.$v.gameInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });

      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await this.getGameList();
    this.hideBeforeLoading = true;

    this.gameTemp = { ...this.gameInfo };
    this.resetItemArraysGameTemp();

    if(this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    async getGameList(params) {
      const result = await this.$store
        .dispatch("adminGame/getGamesAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        

        this.items = result.map((event, index) => {
          let currentImage = 60 + index;
          let picture;

          if (
            !event.picture ||
            (event.picture && !event.picture.includes("/images/"))
          ) {
            picture = `https://picsum.photos/1024/480/?image=${currentImage}`;
          } else {
            picture = `${process.env.VUE_APP_BACKEND + event.picture}`;
          }

          event.picture = picture;

          event.isLoading = false;

          if (event.createdDate) {
            event.createdDate = moment(event.createdDate).format("YYYY-MM-DD");
          }
          return event;
        });

        this.items = orderBy(this.items, ['title'], ['desc']);
        this.totalRows = this.items.length;
        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getGameList(params);
    },
    resetItemArraysGameTemp() {
      if (this.gameTemp.extensionInfos) delete this.gameTemp.extensionInfos;
      this.gameTemp.extensionInfos = cloneDeep(this.gameInfo.extensionInfos);
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    endDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    validate() {
      this.$v.$touch();
      return this.isValidGame;
    },
    ckStateGame(val) {
      let field = this.$v.gameInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    ckStateExtentInfo(index, val) {
      let infos = this.$v.gameInfo.extensionInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    resetData() {
      this.page = 1;
      this.gameId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    async onSubmitGame(close) {
      if (this.validate()) {
        let params = {
          file: this.gameInfo.file,
          gameInfo: "",
          gameId: this.gameId,
        };

        let extensionInfos = {};
        this.gameInfo.extensionInfos.forEach((item) => {
          extensionInfos[item.key] = item.value;
        });

        if(isEmpty(extensionInfos)) extensionInfos = null;

        let gameInfo = {
          title: this.gameInfo.title,
          description: this.gameInfo.description,
          extensionInfos,
        };

        if (this.gameId) gameInfo.gameId = this.gameId;

        params.gameInfo = JSON.stringify(gameInfo);

        this.showMsgConfirmCreate(() => {
          this.isEnableCloseModal = true;
          this.upsertGame(params, close);
        });
      }
    },
    showMsgConfirmCreate(cb) {
      let msg = this.gameId
        ? "このゲームを更新してもよろしいですか？"
        : "このゲームを登録してもよろしいですか？";

      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["game-info-modal"].hide();
        });
    },
    async upsertGame(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminGame/upSertGame", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        this.resetData();
        this.handleSuccessResp(close);
        await this.handleFilterEvents();
      }

      this.isSubmitted = false;
    },
    async infoModal(item) {
      if (!item) {
        this.gameId = "";
        this.gameInfo.title_modal = `新規作成`;
        this.gameInfo.id = "info-modal";
        this.gameInfo.gameId = "";

        this.gameTemp.title_modal = `新規作成`;
        this.gameTemp.id = "info-modal";
        this.gameTemp.gameId = "";

        this.filename = "画像をアップロード";

        this.$root.$emit("bv::show::modal", this.gameInfo.id);
      } else {
        this.gameInfo.title_modal = `更新`;
        this.gameInfo.gameId = item.gameId;

        const result = await this.$store
          .dispatch("adminGame/getDetailGame", item.gameId)
          .catch((err) => {
            console.log(err);
          });

        if (result) {
          this.gameInfo.gameId = "";
          this.gameInfo.title = result.title;
          this.gameId = item.gameId;
          this.gameInfo.description = result.description;
          let extendsInfo = result.extensionInfos
            ? JSON.parse(result.extensionInfos)
            : [{ key: "", value: "" }];

          if (!Array.isArray(extendsInfo)) {
            this.gameInfo.extensionInfos = [];

            Object.entries(extendsInfo).forEach((item) => {
              this.gameInfo.extensionInfos.push({
                key: item[0],
                value: item[1],
              });
            });
          }

          this.filename = this.getNamePicture(result.picture);

          this.$root.$emit("bv::show::modal", this.gameInfo.id);
        }
      }
    },
    async handleDeleteGame(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminGame/deleteGame", this.gameId)
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        this.resetData();
        this.handleSuccessResp(close);
        this.isSubmitted = false;
        await this.handleFilterEvents();
      }
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.title = "";
      this.filters.description = "";
      this.filters.createdDate = "";

      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    deleteModal(item) {
      this.gameId = item.gameId;
      this.$root.$emit("bv::show::modal", "deleteGame");
    },
    resetgameInfo(data) {
      if (
        !isEqual(this.gameInfo, this.gameTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmCloseGame();
        data.preventDefault();
        return;
      }

      this.gameInfo.title_modal = "";
      this.gameInfo.file = null;
      this.gameInfo.title = "";
      this.gameInfo.description = "";
      this.gameInfo.extensionInfos = [];
      this.isEnableCloseModal = false;
      this.filename = '';
      this.$v.$reset();
    },
    handleUpdateGame() {
      this.gameTemp = { ...this.gameInfo };
      this.resetItemArraysGameTemp();
    },
    showModalConfirmCloseGame() {
      this.$bvModal
        .msgBoxConfirm("本当にキャンセルしてもよろしいでしょうか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["game-info-modal"].hide();
          }
        });
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
      });
    },
    addAttribute() {
      if (this.gameInfo.extensionInfos.length > this.MAX_ATTRIBUTES) return;
      this.gameInfo.extensionInfos.push({ key: "", value: "" });
    },
    removeAttribute(index) {
      this.gameInfo.extensionInfos.splice(index, 1);
    },
    async handleDetail(row) {
      row.item.isLoading = true;
      this.isDetailRow = true;

      let currentIdx = this.items.findIndex(
        (game) => game.gameId === row.item.gameId
      );

      if (row.detailsShowing) {
        row.item.isLoading = false;
        this.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      const result = await this.$store
        .dispatch("adminGame/getDetailGame", row.item.gameId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        let content = result;
        content.picture = `${process.env.VUE_APP_BACKEND + content.picture}`;
        content.extensionInfos = JSON.parse(content.extensionInfos);

        content.labelFileName = this.getNamePicture(result.picture);
        
        if (currentIdx > -1) {
          this.items[currentIdx].content = content;
        }

        row.toggleDetails();

        row.item.isLoading = false;
      }
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
  },
};
</script>